import React from 'react'
import PropTypes from 'prop-types'
import Flex from '../../../RadioUI/Flex'
import Button from '../../../RadioUI/Button'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'

const ActionsContainer = ({ isLoading, children, ...restProps }) => {
  if (isLoading) {
    return (
      <Flex py="6" {...restProps}>
        <Button as={LoadingPlaceholder} type={null} delay={null} />
      </Flex>
    )
  }

  return (
    <Flex py="6" {...restProps}>
      {children}
    </Flex>
  )
}

ActionsContainer.propTypes = { isLoading: PropTypes.bool, children: PropTypes.node }

export default ActionsContainer
