/* eslint-disable react/prop-types */
import React, { Children, cloneElement, forwardRef } from 'react'
import PseudoBox from '../PseudoBox'

const AspectRatioBox = forwardRef(({ ratio = 4 / 3, scale = false, center = false, children, ...props }, ref) => {
  const child = Children.only(children)
  const scaleDirection = ratio > 1 ? 'x' : 'y'
  return (
    <PseudoBox
      ref={ref}
      pos="relative"
      _before={{
        h: 0,
        content: `""`,
        d: 'block',
        pb: `${(1 / ratio) * 100}%`
      }}
      {...props}
    >
      {cloneElement(child, {
        pos: 'absolute',
        w: scale && scaleDirection === 'x' ? 'auto' : 'full',
        mx: scale && center ? 'auto' : 0,
        h: scale && scaleDirection === 'y' ? 'auto' : 'full',
        top: scale && scaleDirection === 'y' && center ? '50%' : 0,
        transform: scale && scaleDirection === 'y' && center ? 'translateY(-50%)' : 'initial',
        left: 0,
        right: scale && center ? 0 : 'initial',
        bottom: scale && center ? 0 : 'initial'
      })}
    </PseudoBox>
  )
})

AspectRatioBox.displayName = 'AspectRatioBox'

export default AspectRatioBox
