import PropTypes from 'prop-types'
import React, { useState } from 'react'
import withMobile from '../../components/HOCs/withMobile'
import ShareComponent from './ShareComponent'

const Share = ({
  isMobile = false,
  url,
  timestampSeconds = 0,
  onShare,
  onDialogToggle,
  modalHeader = 'Del lenke',
  children,
  ...restProps
}) => {
  const [isHiddenState, setIsHiddenState] = useState(true)

  return (
    <ShareComponent
      url={url}
      isHiddenState={isHiddenState}
      setIsHiddenState={setIsHiddenState}
      modalHeader={modalHeader}
      onShare={onShare}
      onDialogToggle={onDialogToggle}
      {...restProps}
    >
      {children}
    </ShareComponent>
  )
}

Share.propTypes = {
  isMobile: PropTypes.bool,
  onShare: PropTypes.func,
  onDialogToggle: PropTypes.func,
  children: PropTypes.node,
  url: PropTypes.string,
  modalHeader: PropTypes.string,
  timestampSeconds: PropTypes.number
}

const withIsMobile = withMobile(1200)

export default withIsMobile(Share)
