import React from 'react'
import PropTypes from 'prop-types'
import LineClamp from '../../../RadioUI/LineClamp'

const PageDescription = ({ children, ...restProps }) => {
  return (
    <LineClamp lines={4} lineHeight="lg" fontSize="lg" {...restProps}>
      {children}
    </LineClamp>
  )
}

PageDescription.propTypes = {
  children: PropTypes.string
}

export default PageDescription
