import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import Text from '../../../RadioUI/Text'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'

const PageTitle = forwardRef(({ children, isLoading, ...restProps }, ref) => {
  const pageTitleRef = useRef()
  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        pageTitleRef.current && pageTitleRef.current.focus && pageTitleRef.current.focus()
      }
    }
  })
  if (isLoading) {
    return <LoadingPlaceholder height="16" delay={300} {...restProps} />
  }
  return (
    <Text
      tabIndex="-1"
      _focus={{ outline: 'none' }}
      ref={pageTitleRef}
      as="h1"
      weight="semibold"
      lineHeight="1"
      size={['2xl', '4xl']}
      {...restProps}
    >
      {children}
    </Text>
  )
})

PageTitle.displayName = 'PageTitle'

PageTitle.propTypes = {
  children: PropTypes.string,
  isLoading: PropTypes.bool
}

export default PageTitle
