import React, { Component } from 'react'
import _debounce from 'lodash/debounce'

const isMobile = mobileWidth => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < mobileWidth
  }
  return false
}

export default (mobileWidth = 768) => WrappedComponent =>
  class IsMobile extends Component {
    static displayName = `IsMobile(${WrappedComponent.displayName || WrappedComponent.name || 'WrappedComponent'})`
    constructor() {
      super()

      this.state = {
        isMobile: isMobile(mobileWidth)
      }
    }

    componentDidMount() {
      this.setState({ isMobile: isMobile(mobileWidth) })
      this.debounced = _debounce(this.onResize, 150)
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', this.debounced)
      }
    }

    componentWillUnmount() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.debounced)
      }
      this.debounced.cancel()
    }

    onResize = event => {
      this.setState({
        isMobile: isMobile(mobileWidth)
      })
    }

    render() {
      return <WrappedComponent {...this.props} isMobile={this.state.isMobile} />
    }
  }
