import { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSeriesIsLoading } from './seriesHooks'
import { useEpisodeIsLoading } from './episodeHooks'
import { useSeasonIsLoading } from './seasonHooks'

const useHandleFocus = () => {
  const params = useParams()
  const seriesFocusRef = useRef()
  const seasonFocusRef = useRef()
  const episodeFocusRef = useRef()
  const seriesIsLoading = useSeriesIsLoading()
  const seasonIsLoading = useSeasonIsLoading()
  const episodeIsLoading = useEpisodeIsLoading()

  useEffect(() => {
    if (!seriesIsLoading && !params.programId && !params.seasonId && !!seriesFocusRef.current) {
      seriesFocusRef.current.focus()
    }
  }, [params.programId, params.seasonId, seriesIsLoading])

  useEffect(() => {
    if (!seasonIsLoading && !params.programId && !!params.seasonId && !!seasonFocusRef.current) {
      seasonFocusRef.current.focus()
    }
  }, [params.programId, params.seasonId, seasonIsLoading])

  useEffect(() => {
    if (!episodeIsLoading && !!params.programId && !!episodeFocusRef.current) {
      episodeFocusRef.current.focus()
    }
  })

  return { seriesFocusRef, seasonFocusRef, episodeFocusRef }
}

export default useHandleFocus
