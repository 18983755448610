import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { InView } from 'react-intersection-observer'
import Box from '../../../RadioUI/Box'
import AspectRatioBox from '../../../RadioUI/AspectRatioBox'
import { useColors } from '../../../RadioUI/theme/Color'

// TODO: Less magic numbers?

const topLineHeightLarge = 50
const navMenuHeightLarge = 88

const topLineHeightSmall = 56
const navMenuHeightSmall = 98

const topOffsetLarge = -Math.abs(topLineHeightLarge + navMenuHeightLarge)
const topOffsetMedium = -Math.abs(topLineHeightSmall + navMenuHeightSmall)
const topOffestSmall = 0

const marginBottomOffsetLarge = topOffsetLarge - 162
const marginBottomOffsetMedium = topOffsetMedium - 150
const marginBottomOffsetSmall = topOffestSmall - 76

const gradient = color => css`
  background-image: linear-gradient(180.14deg, ${color.medium}66 39.89%, ${color.medium} 99.88%);
`

const HeroBackdropImage = ({ src, srcSet, isLoading = false, ...restProps }) => {
  const colors = useColors()

  if (src || isLoading) {
    return (
      <Box
        aria-hidden="true"
        className="HeroBackdropImage"
        position="relative"
        zIndex={['hide']}
        top={[`${topOffestSmall}px`, `${topOffestSmall}px`, `${topOffsetMedium}px`]}
        mb={[
          `${marginBottomOffsetSmall}px`,
          `${marginBottomOffsetSmall}px`,
          `${marginBottomOffsetMedium}px`,
          `${marginBottomOffsetLarge}px`
        ]}
        maxH="60vh"
        overflow="hidden"
        w="100%"
        {...restProps}
      >
        <InView threshold={0.1}>
          {({ inView, ref }) => {
            return (
              <AspectRatioBox ratio={16 / 9} maxH="60vh" ref={ref}>
                <Box h="full">
                  {inView && src && (
                    <>
                      <img src={src} srcSet={srcSet} />
                      <Box pos="absolute" top="0" right="0" bottom="0" left="0" css={gradient(colors.main)}></Box>
                    </>
                  )}
                </Box>
              </AspectRatioBox>
            )
          }}
        </InView>
      </Box>
    )
  }

  return null
}

HeroBackdropImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  isLoading: PropTypes.bool
}

export default HeroBackdropImage
