import { css } from '@emotion/react'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

const LineClamp = forwardRef(({ lines, lineHeight, children, ...props }, ref) => {
  const styles = css`
    position: relative;
    span {
      display: block;
      overflow: hidden;
      line-height: ${lineHeight};
      max-height: calc(${lines} * ${lineHeight});
      height: auto;
    }

    @supports (-webkit-line-clamp: 1) {
      span {
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
      }
    }
  `
  return (
    <Box ref={ref} css={styles} {...props}>
      <span>{children}</span>
    </Box>
  )
})

LineClamp.propTypes = {
  lines: PropTypes.number.isRequired,
  lineHeight: PropTypes.string.isRequired,
  children: PropTypes.node
}

LineClamp.displayName = 'LineClamp'

export default LineClamp
